import React, { useMemo } from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import useMeasure from 'react-use-measure';
import Container from '@material-ui/core/Container';

import Layout from '../components/layout';
import SEO from '../components/helpers/seo';

import mq from '../styles/mq';
import useGutters from '../hooks/useGutters';

import { ImageType } from '../types/image';
import { ProductEntity } from '../types/product';
import { ThemeType } from '../styles/theme';

export const query = graphql`
  query($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      title
      slogan
      price
      description {
        description
      }
    }
    bottle: file(name: { eq: "bottle" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 700, fit: COVER, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    workshop: file(name: { eq: "3d-pack" }) {
      childImageSharp {
        fluid(
          maxWidth: 600
          maxHeight: 900
          cropFocus: CENTER
          fit: COVER
          quality: 80
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

interface ProductProps {
  data: {
    shopifyProduct: ProductEntity;
    contentfulProduct: ProductEntity;
    bottle: ImageType;
    workshop: ImageType;
  };
}

const Product: React.FC<ProductProps> = ({
  data: { contentfulProduct, bottle, workshop },
}) => {
  const gutters = useGutters();
  const [ref, { left }] = useMeasure();
  const { color } = useTheme<ThemeType>();

  const product = useMemo(() => contentfulProduct, [contentfulProduct]);

  return (
    <Layout>
      <SEO
        title={`${product.title} - ${product.slogan}`}
        description={product.description}
      />
      <section
        css={css`
          background-color: ${color.primary};
          padding: 100px 0;
          overflow: hidden;

          ${mq(`md`)} {
            padding: 0;
          }
        `}
      >
        <Container disableGutters={gutters}>
          <div
            css={css`
              ${mq(`md`)} {
                display: flex;
                height: 100vh;
              }
            `}
          >
            <div
              ref={ref}
              css={css`
                background-color: ${color.primary};
                display: flex;
                align-items: center;
                margin-bottom: 50px;
                text-align: center;
                position: relative;

                ${mq(`md`)} {
                  flex: 0 0 60%;
                  max-width: 60%;
                  margin-bottom: 0;
                  padding-right: 150px;
                  text-align: left;
                }
              `}
            >
              <div
                css={css`
                  padding: 0 20px;

                  ${mq(`md`)} {
                    padding: 0;
                  }
                `}
              >
                <p
                  css={css`
                    color: ${color.warning};
                    font-size: 20px;
                    font-weight: 900;
                    margin-bottom: 10px;

                    ${mq(`md`)} {
                      margin-bottom: 0;
                    }
                  `}
                >
                  2020
                </p>
                <h1
                  css={css`
                    color: ${color.white};
                    font-size: 40px;
                    font-weight: 900;
                    line-height: 1;
                    margin-bottom: 20px;

                    ${mq(`xs`)} {
                      font-size: 50px;
                    }

                    ${mq(`md`)} {
                      max-width: 400px;
                    }

                    ${mq(`lg`)} {
                      font-size: 80px;
                    }
                  `}
                >
                  {product.title}
                </h1>
                <h2
                  css={css`
                    color: ${color.white};
                    font-size: 20px;
                    font-weight: 800;
                    margin-bottom: 30px;
                    text-transform: uppercase;
                  `}
                >
                  {product.slogan}
                </h2>
                <p
                  css={css`
                    color: ${color.white};
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 70px;

                    ${mq(`lg`)} {
                      font-size: 16px;
                    }
                  `}
                >
                  {product.description.description}
                </p>
                <div
                  css={css`
                    color: ${color.accent};
                    font-size: 60px;
                    font-weight: 700;
                    margin-bottom: 30px;

                    span {
                      font-weight: 400;
                    }
                  `}
                >
                  {product.price} <span>€</span>
                  <div
                    css={css`
                      font-size: 20px;
                    `}
                  >
                    par caisse de 24 bouteilles
                  </div>
                </div>
                <figure
                  css={css`
                    width: 200px;
                    margin: 50px auto 0;
                    padding-right: 15px;

                    ${mq(`md`)} {
                      padding-right: 0;
                      margin: 0;
                      position: absolute;
                      top: 50%;
                      right: -90px;
                      transform: translateY(-50%);
                      z-index: 2;
                      width: 200px;
                      height: 500px;
                    }

                    ${mq(`lg`)} {
                      width: 300px;
                      height: 700px;
                      right: -135px;
                    }
                  `}
                >
                  <Img fluid={bottle.childImageSharp.fluid} />
                </figure>
              </div>
            </div>
            <div
              css={css`
                background-color: ${color.white};

                ${mq(`md`)} {
                  flex: 0 0 40%;
                  max-width: 40%;
                }
              `}
            >
              <div
                css={css`
                  position: relative;

                  ${mq(`md`)} {
                    margin-right: -${left}px;
                  }

                  &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: hsl(179, 100%, 30%, 0.3);
                  }
                `}
              >
                <figure
                  css={css`
                    position: relative;
                    overflow: hidden;

                    ${mq(`md`)} {
                      height: 100vh;
                    }
                  `}
                >
                  <Img
                    fluid={workshop.childImageSharp.fluid}
                    css={css`
                      height: 100%;
                    `}
                  />
                </figure>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default Product;
